export { default as badge } from './badge.svg';
export { default as bag } from './bag.svg';
export { default as box } from './box.svg';
export { default as calendar } from './calendar.svg';
export { default as delivery24 } from './delivery24.svg';
export { default as list } from './list.svg';
export { default as magnifier } from './magnifier.svg';
export { default as padlock } from './padlock.svg';
export { default as percent } from './percent.svg';
export { default as return } from './return.svg';
export { default as return30 } from './return30.svg';
export { default as return60 } from './return60.svg';
export { default as shield } from './shield.svg';
export { default as support } from './support.svg';
export { default as time } from './time.svg';
